<template>
  <div class="cards" id="three-cards">
    <div class="wrapper">
      <div
        class="card-item"
        v-for="(item, index) in cards"
        :key="index"
        @click="showModal(item)"
      >
        <img :src="item.img" alt="" />
        <div class="color"></div>
        <div class="inner">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="info">
            {{ item.info }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :style="style">
      <div class="modal-inner" :style="innerStyle">
        <div class="modal-close" @click="hide"></div>
        <div class="modal-title">
          {{current.title}}
        </div>
        <div class="modal-content">
          {{current.extra}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThreeCards',
  props: {
    cards: Array,
  },
  created() {},
  data() {
    return {
      style: {},
      current: {},
      innerStyle: {},
    };
  },
  methods: {
    showModal(item) {
      const { extra } = item;
      if (!extra) {
        return;
      }
      const top = document.getElementById('three-cards').offsetTop;
      const { scrollTop } = document.documentElement;
      this.style = {
        top: `${(top - scrollTop) * -1}px`,
        display: 'flex',
        left: 0,
        right: 0,
      };
      this.innerStyle = {
        backgroundImage: `url(${item.back})`,
      };
      this.stop();
      this.current = item;
    },
    hide() {
      this.style = {
        display: 'none',
      };
      this.move();
    },
    stop() {
      function mo(e) {
        e.preventDefault();
      }
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', mo, false);
    },
    move() {
      function mo(e) {
        e.preventDefault();
      }
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', mo, false);
    },
  },
};
</script>
<style scoped lang="less">
.cards {
  height: @size460;
  min-height: 230px;
  width: 100%;
  position: relative;
  color: #fff;
  padding-top: @size80;
  display: flex;
  justify-content: center;
  background: #f5f5f5;
}
.wrapper {
  width: @size1200;
  min-width: 600px;
  height: @size300;
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  .card-item {
    width: @size380;
    min-width: 190px;
    height: @size300;
    min-height: 150px;
    border-radius: @size10;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
    }
    &:hover {
      .color {
        background: linear-gradient(
          to bottom,
          rgba(0, 102, 221, 0.5),
          rgba(0, 102, 221, 0.8)
        ) !important;
      }
      .inner {
        justify-content: flex-start;
      }
      .info {
        display: auto;
      }
    }
    .color {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.0),
        rgba(0, 0, 0, 0.5)
      );
    }
    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: @size380;
      min-width: 190px;
      height: @size300;
      min-height: 150px;
      padding-left: @size30;
      padding-right: @size30;
      padding-top: @size40;
      padding-bottom: @size50;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      flex-direction: column;
      color: #fff;
      z-index: 10;
      &:hover {
        justify-content: flex-start;
        .info {
          display: block;
        }
      }
      .title {
        border-left: solid 3px #fff;
        padding-left: @size20;
        font-size: @size32;
        font-weight: 700;
        color: #fff;
        height: @size32;
        min-height: 16px;
        line-height: 1;
      }
      .info {
        margin-top: @size32;
        font-size: @font16;
        line-height: 1.5;
        text-align: left;
        display: none;
      }
    }
  }
}
.modal {
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  display: none;
  position: absolute;
  z-index: 100;
  // display: flex;
  justify-content: center;
  align-items: center;
  .modal-inner {
    width: @size1000;
    min-width: 500px;
    height: @size600;
    min-height: 300px;
    background-size: cover;
    position: relative;
    padding-top: @size100;
    padding-right: @size100;
    padding-left: @size100;
    color: #fff;
    text-align: left;
    .modal-close {
      width: @size48;
      min-width: 24px;
      height: @size48;
      min-height: 24px;
      background-image: url(../assets/images/business/modal-close.png);
      background-size: cover;
      cursor: pointer;
      position: absolute;
      right: @size20;
      top: @size20;
    }
    .modal-title {
      font-size: @size32;
      font-weight: 700;
    }
    .modal-content {
      font-size: @font18;
      line-height: 1.875;
      margin-top: @size70;
    }
  }
}
</style>
