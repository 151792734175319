<template>
  <div class="product-cards-inner">
    <div class="inner">
      <div
        class="card-item"
        v-for="(item, index) in cards"
        :key="index"
        @click="gotoDetail(index, item)"
      >
        <img :src="item.img" alt="" />
        <div class="card-content">
          <div class="card-title">{{ item.title }}</div>
          <div class="card-info">{{ item.info }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCardsInner',
  props: {
    cards: Array,
  },
  methods: {
    gotoDetail(index, item) {
      const { canDetail = true } = item;
      if (!canDetail) {
        return;
      }
      if (item.href) {
        window.location.href = item.href;
        console.log(item.href);
        return;
      }
      const {
        currentRoute: { path },
      } = this.$router;
      const url = `${path}/detail/${index}`;
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped lang="less">
.product-cards-inner {
  display: flex;
  justify-content: center;
  .inner {
    width: @size1200;
    min-width: 600px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card-item {
      width: @size585;
      margin-bottom: @size30;
      height: @size200;
      border-radius: @size10;
      overflow: hidden;
      position: relative;
      padding-top: @size38;
      padding-left: @size42;
      background: #fff;
      text-align: left;
      cursor: pointer;
      img {
        width: @size60;
        min-width: 30px;
        float: left;
      }
      .card-content {
        float: left;
        display: inline-block;
        color: #333333;
        text-align: left;
        width: @size400;
        min-width: 200px;
        margin-left: @size38;
        .card-title {
          font-size: @size24;
        }
        .card-info {
          margin-top: @size35;
          font-size: @font16;
          line-height: 1.875;
          text-align: left;
        }
      }
    }
  }
}
</style>
