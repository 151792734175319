<template>
  <div class="top-pic">
    <img :src="img" alt="business" class="business-img" />
    <div class="breadcrumb">
      <span class="breadcrumb-item" v-for="(name, index) in breadcrumb" :key="index">{{
        name
      }}</span>
    </div>
    <div class="top-content">
      <div class="words">{{ title }}</div>
      <div class="info">{{ info }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopPic',
  props: {
    img: String,
    title: String,
    info: String,
    breadcrumb: Array,
  },
};
</script>
<style scoped lang="less">
.top-pic {
  height: @size600;
  min-height: 300px;
  width: 100%;
  position: relative;
  color: #fff;
  img {
    width: 100%;
  }
}
.breadcrumb {
  position: absolute;
  top: @size172;
  left: @size150;
  font-size: @font14;
  .breadcrumb-item {
    margin-right: 8px;
  }
}
.top-content {
  position: absolute;
  top: @size270;
  left: @size150;
  text-align: left;
  .words {
    font-size: @size50;
    font-weight: 700;
  }
  .info {
    margin-top: @size60;
    font-size: @font18;
    line-height: 1.875;
    width: 590px;
  }
}
</style>
