<template>
  <div class="consult">
    <div class="title first-line">您需要帮助与服务吗？</div>
    <div class="title">千沐科技以人工智能技术为基石，为矿山智能化及AI教育持续赋能</div>
    <div class="btn color-button" @click="goto()">详情咨询</div>
  </div>
</template>

<script>
import { routeUrls } from '../utils/constants';

export default {
  name: 'Consult',
  props: {},
  methods: {
    goto() {
      this.$router.push({ path: routeUrls.about });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.consult {
  width: 100%;
  height: @size400;
  min-height: 200px;
  text-align: center;
  background-image: url(../assets/images/home/consult.jpg);
  background-size: cover;
  padding-top: @size100;
  .title {
    font-size: @size30;
    line-height: 1.8;
    color: #333;
  }
  .first-line {
    font-size: 0.5rem;
    font-weight: 700;
  }
  .btn {
    margin-top: @size40;
    display: inline-block;
    width: @size160;
    min-width: 80px;
    height: @size50;
    min-height: 25px;
    font-size: @size20;
    line-height: 50px;
    color: #fff;
    border-radius: @size25;
    font-weight: 700;
    background: #0066dd;
    cursor: pointer;
  }
}
</style>
